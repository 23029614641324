import React from 'react';
import classNames from 'classnames';

import styles from "./PriceCard.module.scss";
import { tarifCardType, tarifTypes } from '../../redux/types/@types';
import { MarkerIcon } from '../../assets/icons';
import Button from '../Button';
import { ButtonType, ModalWindowTypes } from '../../utils/@globalTypes';
import { tariffsName } from '../../redux/reducers/tariffsSlice';
import { useDispatch } from 'react-redux';
import { setModalWindowData } from '../../redux/reducers/pageSlice';
import { CURRENCY } from '../../utils/constants';

type priceCardProps = {
  tarif:tarifCardType,
  isCurrent?:boolean 
  period: 12 | 6 | 1,
  monthlyPrice: string,
  halfYearPrice:string,
  yearPrice: string,
  isPaying: boolean,
  discont:{
    halfYear:number,
    year:number
  }  
}

const PriceCard = ({
  tarif,
  isCurrent, 
  period,
  monthlyPrice, 
  halfYearPrice,
  yearPrice,
  isPaying,
  discont 
}:priceCardProps) => {

  const {
    label,     
    features,
    code
  } = tarif;

  const hedearLabel = isCurrent ? "текущий тариф" 
  : isPaying ? "выгодное предложение" : ""
  
  const periodName = period===12
  ?"год"
  :period===6
  ?"полгода"
  :"месяц"

  const periodPrice = period===12
  ?yearPrice
  :period===6
  ?halfYearPrice
  :monthlyPrice

  const feat = features.map(item=>{
    return(
      <div className={styles.line} key={item}>
        <div className={styles.icon}>
          <MarkerIcon/>
        </div>        
        <div>{item}</div>
      </div>
    )
  })
  const dispatch = useDispatch();
  const handlerActivate=()=>{
    dispatch(setModalWindowData({
      type: ModalWindowTypes.TARIFF_ACTIVATION, 
      data:{
        code:code,
        period:period,
        monthlyPrice:monthlyPrice,
        halfYearPrice:halfYearPrice,
        yearPrice:yearPrice,
        label:label,
        discont:discont,
        isCurrent:isCurrent
      }}))   
  }
  return (
    <div className={classNames(styles.container, {
      [styles.paying]:isPaying,
      [styles.current]:isCurrent      
    })}>
      <div className={styles.label}>
        {hedearLabel}
      </div>

      <h2 className={styles.header}>
        {label}
      </h2>
      
      {code!==tarifTypes.FREE? 
      <>
        <div className={styles.price}>          
          <span className={styles.second}>{" " + periodPrice + " " +CURRENCY + " / "}</span>
          <span className={styles.third}>{periodName}</span>
        </div>
      </>
      : <>
      <div className={styles.free}>          
        <span className={styles.second}>{" " + "0.00" + " " +CURRENCY + " / "}</span>
        <span className={styles.third}>{periodName}</span>
      </div>
    </>}
      <div className={styles.featBlock}>
        {feat}
      </div>
     
      {/* {(!isCurrent&&code!==tarifTypes.FREE)&& */}
      <div className={styles.btnContainer}>
        <Button
          title={"Перейти"}
          type={ButtonType.PRIMARY_SMALL}  
          onClick={handlerActivate}        
        />
      </div>
      {/* }   */}
    </div>
  )
}

export default PriceCard;