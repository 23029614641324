import { AddressType } from "../../utils/@globalTypes";

export type PayloadWithCallback<Data> = {
  data: Data;
  callback?: (value?: any, value2?: any) => void;
  value?: any;
};

export type LimitWithOffsetPayload = {
  limit?: number;
  offset?: number;
};

export type GetDataPayload = {
  value: number;
  data?: { limit?: number; offset?: number };
};

// RESPONSE TYPES

export type ErrorResponseData = {
  email?: string[];
  first_name?: string[];
  last_name?: string[];
  middle_name?: string[];
  password?: string[];
  phone_number?: string[];
  website?: string[];
  position?: string[];
  // mobile_phone?: string[];
  // work_phone?: string[];
  code?: string[];
  object_title?: string[];
  name?: string[];
  object_type?: string[];
  country?: string[];
  permission?: string[];
  photo?: string[];
  booking_photo?: string[];
  region?: string[];
  locality?: string[];
  address?: string[];
  token?: string;
  unp?: string[];
  entity_address?: AddressType;
  post_address?: AddressType;
  start_date?: string[];
  rental_object?: string[];
};

export type ErrorResponseDetailsData = {
  detail: string;
};

export type ResponseData<T> = {
  count: number;
  next: string;
  previous: string;
  results: T;
};

export type UserInfoType = {
  id: number;
  email: string;
  is_active: boolean;
  status: string;
};

export type ResponseOptionsData = {
  id: number;
  title: string;
};

export type PayloadDataWithId<T> = {
  id: number;
  data: T;
};

export type tarifCardType = {
  label: string;
  code: tarifTypes;
  features: string[];
  isPaying?:boolean
};

export type tarifFeaturesType = {
  subTitle:string,
  tariffs:{
    free:boolean,
    start:boolean,
    pro: boolean,
    pro_plus: boolean
  }
}

export type tarifAbilityType = {
  title:string,
  features: tarifFeaturesType[]
}

export enum tarifTypes { 
  START = "START",
  PRO = "PRO",
  PRO_PLUS = "PRO_PLUS",
  FREE = "FREE"
}