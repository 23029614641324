import { tarifTypes} from './../types/@types';
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type TariffState = {
  currentTarif: tarifTypes,
  priceList: {
    START: number,
    PRO: number,
    PRO_PLUS: number,
    FREE: number  
  },  
  discont:{
    halfYear:number,
    year:number
  },
  tarifFractionDigits:number,
  isPaying:tarifTypes|undefined,
  mainObjectLimit: {
    START: number|null,
    PRO: number|null,
    PRO_PLUS: number|null,
    FREE: number|null 
  },
  rentalObjectLimit: {
    START: number|null,
    PRO: number|null,
    PRO_PLUS: number|null,
    FREE: number|null 
  },

};

const prices = {
  START:65,
  PRO: 118,
  PRO_PLUS:155,
  FREE:0,
}

const initialState: TariffState = {
  currentTarif: tarifTypes.PRO_PLUS,
  priceList: prices,
  discont:{
    halfYear:10,
    year:20
  },
  tarifFractionDigits:2,
  isPaying:tarifTypes.PRO,     /// будет отмечен как выгодный

  mainObjectLimit: {
    START: 1,
    PRO: 2,
    PRO_PLUS: null,
    FREE: 1 
  },
  rentalObjectLimit: {
    START: 10,
    PRO: 10,
    PRO_PLUS: null,
    FREE: 1 
  },
};

const token = localStorage.getItem("accessToken") 
  if(token){ 
    const arrayToken = token?.split('.');
    const tokenPayload = JSON.parse(atob(arrayToken[1]))
    console.log(tokenPayload)
  }

const TariffsSlice = createSlice({
  name: "tariffs",
  initialState,
  reducers: {
    // getBookingList(_, __) {},
    // setBookingList(state, action: PayloadAction<BookingType[]>) {
    //   state.bookingList = action.payload;
    // },
    
    
}});

export const {
  
  
} = TariffsSlice.actions;


export default TariffsSlice.reducer;
export const tariffsName = TariffsSlice.name;

export const TariffsSelectors = {
  getCurrentTarif: (state: RootState) => state.tariffs.currentTarif,
  getPriceList: (state: RootState) => state.tariffs.priceList,
  getTariffsDiscont: (state: RootState) => state.tariffs.discont,
  getTarifFractionDigits: (state: RootState) => state.tariffs.tarifFractionDigits,
  getPayingTarif: (state: RootState) => state.tariffs.isPaying,
};