import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageData } from "../../redux/reducers/pageSlice";
import PriceCard from "../../components/PriceCard";
import { tariffs } from "../../utils/tarifDatas";
import styles from "./Prices.module.scss";
import PricePossibilities from "../../components/PricePossibilities";
import { Arrow2Icon } from "../../assets/icons";
import classNames from "classnames";
import RadioButton from "../../components/RadioButton";
import { TariffsSelectors } from "../../redux/reducers/tariffsSlice";

const Prices = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setPageData({
        pageTitle: "Информация о тарифах",
        pageDescription: "Настройки тарифного плана",
      })
    );

    return () => {
      dispatch(setPageData(null));
    };
  }, []);
  const current = useSelector(TariffsSelectors.getCurrentTarif);  
  const priceList = useSelector(TariffsSelectors.getPriceList);
  const discont = useSelector(TariffsSelectors.getTariffsDiscont);
  const fractionDigits = useSelector(TariffsSelectors.getTarifFractionDigits);
  const payingTarif = useSelector(TariffsSelectors.getPayingTarif);

  const [periodChoice, setPeriodChoice] = useState<number>(0)

  const RadioButtonList = [
    <div key="0">
      <div className={styles.title}>На 12 месяцев</div>
      <div className={classNames(styles.subtitle,{
        [styles.active]:periodChoice===0
      })}>
        Экономия <span>{discont.year}%</span>
      </div>
    </div>, 
    <div key="1">
      <div className={styles.title}>На 6 месяцев</div>
      <div className={classNames(styles.subtitle,{
        [styles.active]:periodChoice===1
      })}>
        Экономия <span>{discont.halfYear}%</span>
      </div>
      </div>, 
    <div className={styles.title} key="2">На 1 месяц</div>
  ];

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const cards = tariffs.map((item,index)=>{
    const monthlyPrice = priceList[item.code].toFixed(fractionDigits)
    const halfYearPrice = (priceList[item.code]*6*(100-discont.halfYear)/100).toFixed(fractionDigits)
    const yearPrice = (priceList[item.code]*12*(100-discont.year)/100).toFixed(fractionDigits);
    const isPaying = payingTarif===item.code ? true : false
    return(
      <div key={index}>
        <PriceCard 
          tarif = {item}
          isCurrent={item.code===current? true: false}  
          period = {periodChoice===0? 12:periodChoice===1?6:1 } 
          monthlyPrice={monthlyPrice}  
          halfYearPrice = {halfYearPrice}     
          yearPrice = {yearPrice}
          isPaying = {isPaying}
          discont = {discont}
        />
      </div>
    )
  })

  return (
    <>
      <h2>Мы рады предложить Вам следующие тарифные планы:</h2>
      <div className={styles.container}>
        Скоро здесь будут тарифы
        {/* <section className={styles.blockWrap}> 
          <div className={styles.radioBtnWrap}>
            <RadioButton
              id="tarif"
              name="tarif"
              btnsList={RadioButtonList}
              onClick={setPeriodChoice}
              activeTab={periodChoice}
              size="price"
            />
          </div>
          <div className={styles.cardsWrapper}>
            {cards}
          </div>
        </section> 
        <div className={styles.abilityWrap}>
          <h3 className={styles.toggleTitle} onClick={()=>setIsOpen(!isOpen)}>
            {isOpen&&"Скрыть весь список возможностей"}
            {!isOpen&&"Показать весь список возможностей"}
            <span className={classNames(styles.arrow, isOpen && styles.arrowOpen )}>
              <Arrow2Icon fill="#0F2664"/>
            </span>
          </h3>
          {isOpen&&<PricePossibilities/>}
        </div> */}
      </div>
    </>
  );
};

export default Prices;